<template>
  <div style="flex: 1; overflow: auto">
      <liste-invites />
  </div>
</template>

<script>
import ListeInvites from '@/views/ListeInvites'
export default {
    components: {
        ListeInvites
    }
}
</script>

<style>

</style>