<template>
    <div class="Component">
    <DynamicContainer transition="pop" :duration="500">
      <div style="flex: 1; display: flex; width: 100%; align-items: center; justify-content: center;">
        <div v-if="profile" style="width: 100%">
                <div style="opacity: .33; z-index: 2; flex: 0; display: flex; align-items: center; justify-content: center; margin: 1rem">
                    <small style="font-family: sans-serif"> Carte Réponse {{profile.code}}</small>
                </div>
            <div class="reponseCarte" data-card>
                <h2 class="nomInvite">{{profile.nom}}</h2>
                <div class="reponse" :class="{'positive': profile.presence === true, 'negative': profile.presence === false, 'neutre': profile.presence === ''}">
                    <span v-if="profile.presence === true">Présence confirmée ({{profile.nombre}} pers.) !</span>
                    <span v-else-if="profile.presence === false">Absence confirmée</span>
                    <span v-else>Pas encore répondu</span>
                </div>
                <div class="choix">
                    <button class="positive" @click="$refs.ModaleReponse.show" :disabled="updating">
                        {{profile.presence ? 'Modifier' : 'Accepter'}}
                    </button>
                    <button class="negative" @click="choisir(false)" :disabled="updating || profile.presence === false">
                        Décliner
                    </button>
                </div>
            </div>
            <div style="margin: 2rem; opacity: .25; ">
                <button @click="() => { resetCode() }" style="margin: .2rem; border: 1px solid rgba(0,0,0,.3); border-radius: 2em; padding: .2em 1em; font-size: .7em">Utiliser un autre code ?</button>
                <button v-if="profile.code" @click="() => { downloadInvite() }" style="margin: .2rem; border: 1px solid rgba(0,0,0,.3); border-radius: 2em; padding: .2em 1em; font-size: .7em" >Télécharger mon invitation</button>
            </div>
        </div>
        <div v-else-if="ready">
            <div class="codeInput" style="font-family: sans-serif">
                <img src="/img/qr.png" alt="" style="height: 100px">
                <br/>
                <small>Saisissez le code figurant sur le coupon-réponse  de votre invitation</small>
                <br/>
                <input
                    ref="codeInput1"
                    type="text"
                    placeholder="XXX"
                    @input="normalizeCodeInput"
                    maxlength="3"
                    autocomplete="off"
                    autocorrect="off"
                    autofill
                    >
                -
                <input
                    ref="codeInput2"
                    type="text"
                    placeholder="XXX"
                    @input="normalizeCodeInput"
                    maxlength="3"
                    autocomplete="off"
                    autocorrect="off"
                    autofill
                    >
                -
                <input
                    ref="codeInput3"
                    type="text"
                    placeholder="XXX"
                    @input="normalizeCodeInput"
                    maxlength="3"
                    autocomplete="off"
                    autocorrect="off"
                    autofill
                    >
                <button class="submitButton" @click="useCode(cCode)">Valider</button>
            </div>
        </div>
        <div v-else></div>
    </div>
    <Explosion ref="explosion" />
    <Modale ref="ModaleReponse" @submit="choisir(true)" @opened="$refs.nbPerson.select()">
        <p>
            Nombre de personnes présentes :
            <input type="number" min="1" max="99" required ref="nbPerson" style="display: inline;" v-model="(profile || {}).nombre"/>
            <br/>( dont 
            <input type="number" min="0" max="99" required ref="nbEnfants" style="display: inline;" v-model="(profile || {}).enfants"/>
            enfants <span style="opacity: .7"> - 12ans</span> )
        </p>
        <template v-slot:buttons>
            <button type="button" @click="(e) => { $refs.ModaleReponse.hide(e); profile = originalProfile }">Annuler</button>
            <div style="flex: 1"></div>
            <button class="primary">Confirmer</button>
        </template>
    </Modale>
    </DynamicContainer>
  </div>
</template>

<script>
import helpers from '@/helpers'
import Explosion from '@/components/ParticleExplosion'
export default {
    components: {Explosion},
  data() {
    return {
        profile: null,
        ready: false,
        updating: false,
        originalProfile: null
    }
  },
  computed: {
      cCode() {
          return [this.$refs.codeInput1.value, this.$refs.codeInput2.value, this.$refs.codeInput3.value].join('-')
      }
  },
  methods: {
    normalizeCodeInput(e) {
        e.target.value = e.target.value.toUpperCase().replace(new RegExp(`[^${helpers.codeChars}]`), '')
        if(e.target.value.length == 3) {
            if (e.target.nextElementSibling) {
                e.target.nextElementSibling.focus()
            }
        }

    },
    choisir(reponse) {
        this.updating = true
        const enfants = parseInt(this.profile.enfants, 0)
        const adultes = parseInt(this.profile.nombre, 0) - enfants
        fetch(
            '/api/invite/'+this.profile.code+'/reponse',
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({reponse, adultes, enfants})
            }
        ).then(() => {
            this.profile.presence = reponse
            this.profile.adultes = adultes
            this.profile.enfants = enfants
            this.ready = true

            const elm = document.querySelector('.reponse').getBoundingClientRect()
            this.$refs.explosion.explode((elm.left + elm.width / 2), elm.top, {color: reponse == true ? '#4d4' : '#d44'})
        }).catch(() => {
            this.ready = true
        }).finally(() => {
            this.updating = false
            this.$refs.ModaleReponse.hide()
            this.originalProfile = {...this.profile}
        })

    },
    resetCode() {
        localStorage.removeItem('G&E_code')
        this.$router.replace({name: this.$route.name, query: {code: null}})
        this.profile = null
    },
    useCode(code) {
        fetch('/api/decode/' + code)
            .then(r => (r.json()))
            .then(json => {
                if(json.code == code) {

                    localStorage.setItem('G&E_code', code)

                    this.$router.replace(
                        {
                            name: this.$route.name,
                            query: {code: code}
                        }
                    )

                    this.profile = json
                    this.originalProfile = {...json}
                    this.ready = true
                } else {
                    alert(json.message)
                    this.ready = true
                }
            })
            .catch((e) => {
                alert(e.message)
                this.ready = true
            })
    }, 
    downloadInvite() {
        window.open(`/PDFs/${this.profile.code}.pdf`)
    }
  }, 
  mounted() {
      if(this.$route.query.code) {
          this.useCode(this.$route.query.code)
      } if(localStorage.getItem('G&E_code')) {
          this.useCode(localStorage.getItem('G&E_code'))
      } else {
          this.ready = true
      }
  }
}
</script>

<style lang="stylus" scoped>
.Component
    flex 1
    display flex
    overflow auto
    align-items center
    flex-direction column
    justify-content center
    perspective 800px
    transform-style preserve-3d
.codeInput
    margin auto
    padding 1em
    width 256px
    border-radius .4em
    background white
    border 1px solid rgba(0,0,0,.1)
    box-shadow 0 2px 5px 1px rgba(0,0,0,.1)
    small
        display block
        font-size .85rem
        opacity .5
        margin -1rem auto 1rem auto
    input
        width calc(3ch + 1rem)
        padding .5rem
        text-align center
        border 1px solid rgba(0,0,0,.1)
        border-radius 3px
        outline none
        font-family sans-serif
        &:focus
            background rgba(0,0,0,.08)
.reponseCarte
    width 100%
    min-width 340px
    max-width calc(100% - 2rem)
    box-sizing border-box
    height 230px
    margin auto
    display flex
    flex-direction column
    animation 1s card-enter ease-out
    backface-visibility hidden
    background white
    transform-style flat
    perspective 600px
    &>*
        flex 1
    .nomInvite
        display flex
        align-items center
        justify-content center
        font-family calligraphy
        font-weight normal
        z-index 2
        font-size 1.75em
        margin auto -.5rem
        white-space nowrap
        position relative
        overflow hidden
        color #222
        &::before
            content ''
            animation 1.5s nominvite-enter ease-out forwards
            height 3rem
            top calc(50% - 1.5rem)
            width 1rem
            filter blur(3px)
            background rgba(255,255,255,.66)
            position absolute
    .choix
        display flex
        flex 1
        align-items center
        &>button
            flex 1
            margin .25rem
            border 1px solid rgba(0,0,0,.1)
            font-family sans-serif
            font-size 1rem
            background rgba(0,0,0,.02)
            border-radius .2em
            transition .1s
            cursor pointer
            opacity .7
            height 2.25em
            &:hover
                background rgba(0,0,0,.01)
                opacity 1
                &:not(:disabled).positive
                    color #9D9
                &:not(:disabled).negative
                    color #D99
    .reponse
        border-radius 2rem
        padding .25rem 2rem
        flex 0
        opacity .66
        margin .5rem auto
        font-style italic
        animation 1.5s reponse-enter ease-out
        transition .5s
        font-family sans-serif
        font-size 11pt
        &.positive
            box-shadow 0 0 0 1pt #9D9
            color #9D9
        &.negative
            box-shadow 0 0 0 1pt #D99
            color #D99
        &.neutre
            opacity .5
            background rgba(0,0,0,.07)

.submitButton
    padding .5rem 1rem
    margin .5rem auto
    border-radius .2rem
    cursor pointer
    clear both
    border 1px solid rgba(0,0,0,.1)
    background salmon
    color white
    font-size 1.2rem


@keyframes card-enter
    from
        transform scale(0) rotateX(-80deg) rotateY(-60deg) rotateZ(20deg)
        filter brightness(80%)
    60%
        transform scale(1) rotateX(20deg) rotateY(10deg) rotateZ(-5deg)
    80%
        filter brightness(110%)
    to
        transform scale(1)

@keyframes reponse-enter
    from
    40%
        transform rotateY(-90deg)
    85%
        transform rotateY(20deg)
    to
        transform rotateX(0deg)

@keyframes nominvite-enter
    from
        left -1rem
        transform skewX(-30deg)
    50%
        left 100%
        opacity 1
    100%
        left -5rem
        transform skewX(30deg)
        opacity 0

@media screen and (min-width: 480px) 
    .Component
        font-size 10pt
    .nomInvite
        font-size 3.3em
    .reponseCarte
        width 380px
        height 230px
</style>