class Helpers {
    static codeChars = "ABCDEFGHJKMNPQRTUVWXYZ2346789"

    static rand = (nb) => {
        var text = "";
        var possible = Helpers.codeChars;

        for (var i = 0; i < nb; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
    static uid  = (nb) => {
        let code = []
        for( let i = 0; i < nb; i++ ) {
            code.push(Helpers.rand(3))
        }
        return code.join('-')
    }
}

module.exports = Helpers