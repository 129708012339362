<template>
  <div class="home">
      <br/>
    <DynamicContainer :parent="selectHome()" transition="slideUp" v-if="!$isMobile" style="margin: 6rem auto" @shown="onCounterVisible" >
      <img src="/img/rings.svg" alt="rings" style="height: 5rem; width: 5rem; margin: 2rem" />
      <h1 class="explosionTarget">Élodie et Grégory vous remercient de les avoir accompagnés pour de leur mariage.</h1>
      <Countdown :date="$date" />
      <Explosion ref="explosion" />
    </DynamicContainer>

    <DynamicContainer :parent="selectHome()" transition="slideUp" duration="800" v-if="$isMobile" @shown="onCounterVisible">
      <div style="height: calc(100vh - 5rem); padding: 1.5rem; display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <p class="explosionTarget">Élodie et Grégory vous remercient de les avoir accompagnés pour de leur mariage</p>
        <img src="/img/rings.svg" alt="rings" style="height: 5rem; width: 5rem; margin: 2rem" />
        <Explosion ref="explosion" />

        <img src="/img/scrolldown.svg" alt="v" style="animation: scroll-indicator 1.5s ease-in-out infinite; margin: 5rem auto 0rem auto; height: 3rem; width: 3rem; filter: invert(75%) opacity(50%);" v-if="$isMobile">
      </div>
    </DynamicContainer>

  <div v-if="showAll" id="showAllBlock">
    <div :class="$isMobile ? 'flexCol' : 'flexLarge'">
          <div data-card style="display: flex; flex-direction: column; align-items: flex-start">
            <div style="display: flex; align-items: center">
              <div class="calendarIcon">
                <small class="month">
                  {{$truedate.toLocaleDateString('fr', { month: 'long'})}}
                </small>
                <div class="day">
                  {{$truedate.toLocaleDateString('fr', { day: 'numeric'})}}
                </div>
                <small class="dayOfWeek">
                  {{$truedate.toLocaleDateString('fr', { weekday: 'long'})}}
                </small>
              </div>
              &nbsp;
              &nbsp;
              <div style="display: flex; flex-direction: column; align-items: flex-start">
                Mariage civil en mairie de Colmar
                <small style="opacity: .5; font-size: 1rem">Office à 17h en présence des témoins</small>
              </div>
            </div>
            <br />
            <iframe id="gmap" class="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2665.5946973295518!2d7.355785916280135!3d48.079465679218806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479165e0d7b6735f%3A0xf85f31f1f09534c!2sMairie%20de%20Colmar!5e0!3m2!1sfr!2sfr!4v1650358837164!5m2!1sfr!2sfr" frameborder="0" allowfullscreen="" ></iframe>
          </div>
          <div data-card style="display: flex; flex-direction: column; align-items: flex-start; text-align: left">
            <div style="display: flex; align-items: center">
              <div class="calendarIcon">
                <small class="month">
                  {{$date.toLocaleDateString('fr', { month: 'long'})}}
                </small>
                <div class="day">
                  {{$date.toLocaleDateString('fr', { day: 'numeric'})}}
                </div>
                <small class="dayOfWeek">
                  {{$date.toLocaleDateString('fr', { weekday: 'long'})}}
                </small>
              </div>
              &nbsp;
              &nbsp;
              <div style="display: flex; flex-direction: column; align-items: flex-start">
                Célébration à l'étang de pêche d'Issenheim
                <small style="opacity: .5; font-size: 1rem">À partir de 15h30</small>
              </div>
            </div>
            <br />
            <iframe id="gmap" class="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2675.0688246792765!2d7.253197716276037!3d47.89635317920512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47917923da16f6e3%3A0xbb26f5af10fb77a!2sEtang%20de%20peche%20Issenheim!5e0!3m2!1sfr!2sfr!4v1650358771416!5m2!1sfr!2sfr" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <br />
            <b>Coordonnées des célébrants</b>
            <small style="font-size: 1rem; padding: .5rem 0">
              Pour toute question concernant la préparation et l'office de la cérémonie laïque, ou si vous voulez intervenir pour prononcer votre discours le plus élogieux, vous pouvez contacter directement John et Cécile.
            </small>
            <table v-if="userLogged" style="font-size: 1rem">
            <tr>
              <td>John: </td>
              <td><a style="text-decoration: none; line-height: 2.5rem"  href="tel:0672041603">06 72 04 16 03</a></td>
            </tr>
            <tr>
              <td>Cécile:</td>
              <td><a  style="text-decoration: none; line-height: 2.5rem" href="tel:0678809821">06 78 80 98 21</a></td>
            </tr>
            </table>
            <div v-else style="color: #D44; background: #DD444422; border-radius: .4rem; padding: .5rem;">
                <small>Ces informations sont confidentielles, merci de saisir votre code d'invitation personnel sur la page "<router-link :to="{name: 'Reponse'}">Répondre</router-link>" pour les faire apparaître</small>
            </div>
            <br/>
            <b>Programme de la journée</b>
            <div style="font-size: 1rem">
              <p>
                <b>15h30</b>: Accueil des invités
              </p>
              <p>
                <b>16h00</b>: Cérémonie Laïque
              </p>
              <p>
                <b>17h00</b>: Petite collation
              </p>
              <p>
                <b>17h30</b>: Séance photo, animations
              </p>
              <p>
                <b>20h00</b>: À table !
              </p>
              <p>
                <b>22h45</b>: Surprise et fin des festivités
              </p>
            </div>
          </div>
    </div>
    <div data-card>
      <div style="display: flex; align-items: center">
        <img src="/img/ic_bed.svg" style="height: 3.5rem; width: 3.5rem;"/>
        &nbsp;
        Accueil et hébergement
      </div>

      <div style="display: flex; flex-wrap: wrap">
        <div style="flex: 1; font-size: 1rem; text-align: left; min-width: 300px">
          <p>Besoin d'un toit pour la nuit ?</p>
          <p>
            Contactez-nous !
          </p>
        </div>

        <div style="display: flex; flex-direction: column; padding: .5rem; font-size: .9rem; max-width: 250px;">
          <div style="display: flex; align-items: center; padding: .25rem">
            <img src="/img/ic_wifi.svg" style="height: 2rem; width: 2rem;"/>
            &nbsp;
            Wifi gratuit
          </div>
          <div style="display: flex; align-items: center; padding: .25rem">
            <img src="/img/ic_wc.svg" style="height: 2.5m; width: 2rem;"/>
            &nbsp;
            Toilettes gratuites
          </div>
          <div style="display: flex; align-items: center; padding: .25rem">
            <img src="/img/ic_cat.svg" style="height: 2.em; width: 2rem;"/>
            &nbsp;
            <div style="display: flex; flex-direction: column; align-items: flex-start">
              Ronrons de chats gratuits
              <small style="opacity: .5">(selon disponibilité)</small>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import Explosion from '@/components/ParticleExplosion'
import Countdown from '@/components/Countdown'
export default {
  name: 'Home',
  components: {
    Explosion,
    Countdown
  },
  data: () => ({
    userLogged: localStorage.getItem('G&E_code'),
    showAll: false
  }),
  methods: {
    onCounterVisible() {
      if(!this.showAll) {
        setTimeout(() => {
          this.showExplosion1()
          this.showAll = true
        }, 300)
      }
    },
    selectHome() {
      return document.querySelector('.home')
    },
    showExplosion1() {
      const elm = document.querySelector('.explosionTarget').getBoundingClientRect()
      setTimeout(() => {
        this.$refs.explosion.explode((elm.left + elm.width / 2), (elm.top), {color: 'purple', duration: 1000})
      }, 0)
      setTimeout(() => {
        this.$refs.explosion.explode((elm.right), (elm.top + elm.height / 2), {color: 'royalblue'})
      }, 400)
      setTimeout(() => {
        this.$refs.explosion.explode((elm.left), (elm.bottom), {color: 'salmon'})
      }, 800)
      setTimeout(() => {
        this.$refs.explosion.explode((elm.left + elm.width / 2), (elm.top + elm.height / 2), {color: 'gold'})
      }, 1400)
    }
  },
  mounted() {
    this.showAll = !!this.$isMobile
  }
}
</script>

<style lang="stylus" scoped>
.home
#showAllBlock
  flex 1
  display flex
  flex-direction column

.home
  overflow-y auto
  overflow-x hidden

  .message
    width 80%
    max-width 600px
    margin 1rem auto
    color rgba(0,0,0,.75)

  .gmap
    width 100%
    min-height 18rem
    border-radius .4rem
    box-shadow 0 0 0 1px  rgba(0,0,0,.15)
#showAllBlock
  animation showAllBlock-enter .3s ease-out


.calendarIcon
  display inline-flex
  flex-direction column
  width 3.5rem
  height 3.5rem
  font-family sans-serif
  border-radius .25rem
  overflow hidden
  box-shadow inset 0 0 0 1px rgba(0,0,0,.1), 0 0 .3rem rgba(0,0,0,.2)
  text-align center
  small.month
    height 1rem
    padding 0 .75rem
    font-size .7rem
    text-align center
    background linear-gradient(to bottom, #dd4444, #BB2222)
    color white
    text-shadow 0 1px 1px rgba(0,0,0,.35)
    box-shadow 0 0 0 1px rgba(0,0,0,.2)
    position relative
    &::before
    &::after
      content ''
      display block
      height .3rem
      width .3rem
      background white
      box-shadow 0 0 0 1px rgba(0,0,0,.1), inset 0 1px 2px 1px rgba(0,0,0,.2)
      border-radius 50%
      position absolute
      top .3rem
    &::before
      left .3rem
    &::after
      right .3rem
  div.day
    flex 1
    display flex
    align-items center
    justify-content center
    font-size 1.75rem
    color #333
  small.dayOfWeek
    font-size .55rem
    height 1rem
    color rgba(0,0,0,.5)
    margin-top -.35rem


.flexLarge
  display flex
  flex-wrap wrap
  align-items flex-start
  &>* 
    flex 1

.flexCol
  display flex
  flex-direction column
  align-items flex-start
  flex-wrap wrap

@keyframes showAllBlock-enter
  from
    opacity 0
    transform translateY(100px)
    filter blur(4px)
  to
    opacity 1
    transform translateY(0)
    filter blur(0)
</style>
<style lang="stylus">
@keyframes scroll-indicator
  0%
    transform translateY(-1rem)
  0%
  20%
    opacity 0
  50%
    opacity .5
  to
    opacity 0
    transform translateY(1rem)
</style>