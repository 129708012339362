<template>
  <div class="modaleBackdrop" ref="backdrop" v-show="isOpen" :class="{'leaving': leaving}">
      <form class="modaleDialog" ref="dialog" @submit.prevent.stop="$emit('submit')" :class="{'leaving': leaving}">
          <div class="title" v-if="this.$slots.title">
            <slot name="title" />
          </div>
          <div class="content">
            <slot />
          </div>
          <div class="buttons"  v-if="this.$slots.buttons">
            <slot name="buttons" />
          </div>
      </form>
  </div>
</template>

<script>
export default {
    data: () => ({
        isOpen: false,
        leaving: false
    }),
    methods: {
        show() {
            this.isOpen = true
            document.querySelector('#app').appendChild(this.$refs.backdrop)
            setTimeout(() => {
                this.$emit('opened')
            }, 250)
        },
        hide() {
            this.leaving = true
            this.$refs.dialog.addEventListener('animationend', this.onHidden)
        },
        onHidden() {
            this.isOpen = false
            this.leaving = false
            this.$refs.dialog.removeEventListener('animationend', this.onHidden)
            this.$emit('hidden')
        }
    },
    mounted() {
        document.querySelector('#app').appendChild(this.$refs.backdrop)
    },
    beforeUnmount() {
        this.$refs.backdrop.remove()
    }
}
</script>
<style lang="stylus">

.modaleBackdrop
    position fixed
    height 100%
    width 100%
    top 0
    left 0
    display flex
    align-items center
    justify-content center
    z-index 9
    background rgba(0,0,0,.25)
    backdrop-filter blur(2px)
    animation .3s backdrop-enter ease-out
    &.leaving
        animation .25s backdrop-leave ease-in
    .modaleDialog
        background white
        border-radius .75rem
        padding 1rem
        box-sizing border-box
        animation .3s dialog-enter ease-out
        box-shadow 0 0 2rem 1px rgba(0,0,0,.25), 0 0 0 1px rgba(0,0,0,.25), inset 0 0 1px 1px rgba(255,255,255,.125)
        min-width 250pt
        overflow hidden
        display flex
        flex-direction column
        &.leaving
            animation .25s dialog-leave ease-in
        &>*
            padding .5rem
            border-radius .5rem
        .title
            color var(--color-blue)
        .content
            flex 1
            overflow auto
            display flex
            flex-direction column
        .buttons
            display flex
            align-items center
            & > button ~ button
                margin-left .75rem

        button
            padding .35rem .75rem
            border-radius .5rem
            border none
            background transparent
            color rgba(0,0,0,.4)
            &.primary
                border 1px solid rgba(0,0,0,.1)
                background salmon
                color white

@keyframes backdrop-enter
    from
        opacity 0

@keyframes dialog-enter
    from
        transform translateY(-60px)
        opacity 0

@keyframes backdrop-leave
    to
        opacity 0

@keyframes dialog-leave
    to
        transform translateY(-60px)
        opacity 0
</style>