<template>
  <div id="shell">
  <header v-if="$isMobile">
    <div class="mainTitle">
      <span class="nom elle">Élodie</span>
      <small>&</small>
      <span class="nom lui">Grégory</span>
    </div>
  </header>
  <header v-if="!$isMobile">
    <!-- <img src="/img/app-icon.png" alt="" style="height: 48px; width: 48px; margin: .5em .75em .5em .5em"> -->
    <div class="mainTitle">
      <span class="nom elle">Élodie</span>
      <small>&</small>
      <span class="nom lui">Grégory</span>
    </div>
    <div style="flex: 1"></div>
    <MainMenu />
  </header>
  <router-view id="rview"/>
  <footer v-if="$isMobile">
<!--     <Countdown :date="$date" />
-->    
 <MainMenu />
  </footer>
  </div>
</template>

<script>
import MainMenu from '@/views/MainMenu'

export default {
    components: {
        MainMenu
    }
}
</script>


<style lang="stylus">
@font-face 
    font-family calligraphy
    src url('/fonts/Milasian Circa.otf')

@font-face 
    font-family calligraphy-title
    src url('/fonts/Grethania Script Reguler.ttf')

@font-face
    font-family clock
    src: url(/fonts/LED_CLOCK.ttf)


*
    -webkit-tap-highlight-color transparent

html
body
  margin 0
  padding 0
  width 100%
  height 100%
  
#app
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  display flex
  overflow hidden
  width 100%
  height 100%
  font-size 16pt
  font-family calligraphy
#rview
  background #fbfbfb url(/img/bg.jpg) center center
  background-size cover
  //font-size 11.5pt


h1
h2
h3
  font-family calligraphy-title
  font-weight normal
  color salmon

h1
  background linear-gradient(90deg, salmon, #b2789d, royalblue)
  -webkit-background-clip text
  -webkit-text-fill-color transparent

#shell
    display flex
    flex 1
    flex-direction column
    overflow hidden
    header
        z-index 3
        min-height 45pt
        padding 0 calc(50% - 180px)
        display flex
        align-items center
        justify-content center
        background white
        color salmon
        box-shadow 0 0 .5em 1px rgba(0,0,0,.2)
        overflow hidden
        .mainTitle
            font-family calligraphy-title
            font-size 2.25em
            margin .25em 0
            position relative
            white-space nowrap
            &::before
              content ''
              z-index 2
              animation .75s maintitle-enter ease-out forwards
              animation-delay .75s
              height 3rem
              top calc(50% - 1.5rem)
              width 1rem
              background rgba(255,255,255,.6)
              backdrop-filter blur(2px)
              position absolute
            .nom
                padding 0 .15em
                z-index 2
                display inline-block
                &.elle
                    background linear-gradient(90deg, salmon, #b2789d)
                    -webkit-background-clip text
                    -webkit-text-fill-color transparent
                    animation 1s enter-elle ease-out forwards
                &.lui
                    background linear-gradient(90deg, #b2789d, royalblue)
                    -webkit-background-clip text
                    -webkit-text-fill-color transparent
                    animation 1s enter-lui ease-out forwards
            small
                //font-family serif
                color black
                opacity .2
                z-index 1
                display inline-block
                transform scale(.8)
    footer
        background white
        padding .5em
        box-shadow 0 0 .5em 1px rgba(0,0,0,.2)
        z-index 3

[data-card]
  display block
  margin 1rem
  padding 1.5rem
  border-radius .75rem
  box-shadow 0 .25rem .5rem 1px rgba(0,0,0,.2)
  font-family sans-serif
  background white

input[type="text"]
input[type="number"]
  padding .25rem
  border-radius .2rem
  border 1px solid rgba(0,0,0,.15)
  outline-color salmon

@media screen and (min-width 480px)
  #app
    header
      padding .5em 1em
      justify-content flex-start
      .mainTitle
        margin-bottom 0
@media screen and (min-width 1440px)
  #app
    header
      padding .5em calc(50% - 700px)
    #rview
      padding .5em calc(50% - 700px)

@keyframes enter-elle
  from
    transform translate(.5em, .3em)
    opacity 0
  to
    transform translate(0, 0)

@keyframes enter-lui
  from
    transform translate(-.5em, -.3em)
    opacity 0
  to
    transform translate(0, 0)

@keyframes maintitle-enter
    from
        left -2em
        transform skewX(-30deg)
    to
        left calc(100% + 1em)
        transform skewX(30deg)

</style>
