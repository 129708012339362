<template>
<div style="flex: 1; display: flex; flex-direction: column; overflow: auto">
    <h1>
        Photos
    </h1>
    <div style="font-family: sans-serif" id="albumsList">

        <router-link :to="`/photos/${album.name}`" v-for="album in state.albums" :key="album.name">
            {{album.name}}
            <small>({{Math.floor(album.photos)/2}})</small>
        </router-link>

        <router-link :to="`/photos_upload`" style="background: royalblue; color: white; border-radius: .5rem; padding: 1rem; display: flex; align-items: center;" :style="$isMobile ? '' : 'position: fixed; bottom: 2rem; right: 3rem;'">
            <span style="font-size: 1.85rem; line-height: 1rem">+</span>
            &nbsp;
            <small style="font-size: .85rem">Ajoutez vos photos !</small>
        </router-link>
    </div>
    <div id="album" style="text-align: center;">
        <button @click="showModalePic(`/api/albums/${route.params.album}/${photo.replace('_thumb', '')}`)" v-for="photo in state.currentAlbum.filter((p) => p.indexOf('_thumb') > -1)" :key="photo" style="height: 200px; width: 200px; display: inline-flex; overflow: hidden; align-items: center; justify-content: center; background: #eee; border-radius: .5rem; margin: .5rem; border: none; background: #f2f2f2">
            <img :src="`/api/albums/${route.params.album}/${photo}`" style="aspect-ratio: 1 1; max-height: 95%; max-width: 95%;" />
        </button>
    </div>
    <Modale ref="modalePic" @hidden="() => { state.currentPic = undefined }">
        <div style="height: 80vh; width: 80vw; display: flex; align-items: center; justify-content: center;">
            <img :src="state.currentPic" style="aspect-ratio: 1 1; max-height: 95%; max-width: 95%;" />
        </div>
        <button @click="modalePic.hide">
            Fermer
        </button>
    </Modale>
</div>
</template>

<script setup>
    import {reactive, onMounted, ref, watch} from 'vue'
    import {useRoute, useRouter} from 'vue-router'
    import axios from 'axios'

    const router = useRouter()

    const modalePic = ref()

    const route = useRoute()

    const state = reactive({
        albums: [],
        currentAlbum: [],
        currentPic: undefined,
    })

    const showModalePic = (url) => {
        state.currentPic = url
        modalePic.value.show()
    }

    const loadAlbum = (name) => {
        state.currentAlbum = []
        axios.get(`/api/albums/${name}`).then((response) => {
            state.currentAlbum = response.data
        })
    }

const load = () => {
    axios.get(`/api/albums`).then(response => {
            state.albums = response.data
            if(route.name == 'Photos') {
                if (route.params.album) {
                    loadAlbum(route.params.album || 'photobooth')
                } else {
                    router.push('/photos/photobooth')
                }
            }
        })
}

    onMounted(() => {
        load()
    })

    watch(() => route.path, load)
</script>

<style lang="stylus" scoped>
    #albumsList
        display flex
        flex-wrap wrap
        justify-content center
        & > *
            margin .5rem
            border-radius 1rem
            padding .2rem 1rem
            text-decoration none
            & > small
                opacity .6
            &.router-link-active
                color white
                background salmon
            &:not(.router-link-active)
                color salmon
</style>