<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    explode(scrX, scrY, options) {
      let pcolor = (options || {}).color || "random";
      const particles = (options || {}).nb || 48;
      const explosion = document.createElement("div");
      explosion.className = "explosion";

      document.body.appendChild(explosion);

      explosion.style.left = (scrX - (explosion.getBoundingClientRect().width / 2)) + 'px';
      explosion.style.top = (scrY - (explosion.getBoundingClientRect().height / 2)) + 'px';

      const rand = (min, max) => {
        return Math.floor(Math.random() * (max + 1)) + min;
      };

      for (var i = 0; i < particles; i++) {
        var x =
            explosion.clientWidth / 2 +
            rand(80, 150) *
              Math.cos(
                (2 * Math.PI * i) / rand(particles - 10, particles + 10)
              ),
          y =
            explosion.clientHeight / 2 +
            rand(80, 150) *
              Math.sin(
                (2 * Math.PI * i) / rand(particles - 10, particles + 10)
              );
        if (pcolor == "random") {
          pcolor = 'rgb(' + rand(0, 255) + ", " + rand(0, 255) + ", " + rand(0, 255) + ')';
        }
        const alpha = rand(25,100) / 100
        const colorShift = rand(-30,40)

        const elm = document.createElement("div");
        elm.className = "particle";
        elm.style.backgroundColor = pcolor;
        elm.style.top = y + "px";
        elm.style.left = x + "px";
        elm.style.filter = `hue-rotate(${colorShift}deg)`;
        elm.style.opacity = alpha;
        elm.style.animation = `pop ${(options || {}).duration || 800}ms forwards`;
        elm.style.animationTimingFunction = 'cubic-bezier(.28,.04,.74,1)';
        elm.style.boxShadow = `box-shadow 0 0 0 ${pcolor}`;

        if (i == 0) {
          ['webkitAnimationEnd', 'oanimationend', 'msAnimationEnd', 'animationend'].forEach((e) => {
            elm.addEventListener(e, () => {
              explosion.remove();
            })
          })
        }
        explosion.append(elm);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="stylus">
.explosion {
  position: fixed;
  width: 400px;
  height: 400px;
  pointer-events: none;
  z-index: 9999;
  perspective 600px
  transform-style preserve-3d

  .particle {
    position: absolute;
    width: 5px;
    height: 5px;
    transform-origin bottom center
    border-radius: 50%;
  }
}

@keyframes fall
  from
    margin-top 0
  to
    margin-top 4em


@keyframes pop {

  from {
    top: 50%;
    left: 50%;
    transform: scale(.3)
  }


  to {
    opacity: 0;
    transform: scale(1.5)
    box-shadow 0 20px 6px rgba(0,0,0,.0)!important
  }
}
</style>