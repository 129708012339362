import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import {io} from 'socket.io-client'
import ModaleComponent from '@/components/Modale'
import DynamicContainer from '@/components/DynamicContainer'


const ws = io()

const app = createApp(App)
    .use(router)

app.component('Modale', ModaleComponent)
app.component('DynamicContainer', DynamicContainer)

app.config.globalProperties.$isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
app.config.globalProperties.$ws = ws
app.config.globalProperties.$date = new Date(2022, 5, 25, 15)
app.config.globalProperties.$truedate = new Date(2022, 5, 24, 17, 30)
app.config.globalProperties.$qrdomain = 'http://m.orym.net'

setTimeout(() => {
    app.mount('#app')
}, 1500)