<template>
    <div id="Component" data-card>
    <DynamicContainer transition="slideUp" :duration="1000">
        <h1>Liste des invités</h1>
        <div class="list">
            <div v-for="(invite, index) in invites" :key="index" class="invite" :class="{present: invite.presence, absent: invite.presence === false}">
                {{invite.nom}} <small style="opacity: .5; font-family: sans-serif; font-size: .5em" v-if="invite.presence && invite.nombre">
                    &nbsp;( {{invite.adultes}} adulte{{invite.adultes > 1 ? 's' : ''}} 
                    <span v-if="invite.enfants"> + {{invite.enfants}} enf.</span>
                    )
                </small>
                <div style="flex: 1"></div>
                <div v-if="invite.code" style="display: flex; font-family: sans-serif; align-items: center; font-size: 7pt; color: black;border: 1px solid rgba(0,0,0,.1); justify-content: center; border-radius: .5rem; overflow: hidden; position: absolute; right: 0; background: white; font-family: monospace;">
                    <a :href="`https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${$qrdomain}/reponse?code=${invite.code}&choe=UTF-8`" target="code">
                        <img :src="`https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${$qrdomain}/reponse?code=${invite.code}&choe=UTF-8`" height="32"/>
                    </a>
                    {{invite.code}}
                </div>
            </div>
        </div>
    </DynamicContainer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            invites: []
        }
    },
    mounted() {
        fetch('/api/invites').then((res) => (res.json())).then((json) => {this.invites = json.sort((a,b) => (a.nom.localeCompare(b.nom)))})
        this.$ws.on('participation', (json) => {this.invites = json.sort((a,b) => (a.nom.localeCompare(b.nom)))})
    }
}
</script>

<style lang="stylus" scoped>
.list
    padding .5em
    display block
    overflow auto
    flex 1

.invite
    padding .5em
    text-align left
    display flex
    flex-wrap wrap
    align-items center
    min-width 150pt
    transition .2s
    position relative
    &:not(:last-child)
        border-bottom 1px solid rgba(200,130,180, .15)
    &::before
        content ''
        height 1rem
        width 1rem
        line-height 1rem
        display inline-flex
        align-items center
        justify-content center
        font-weight bold
    &.present
        color #2B2
        &::before
            content '✓'
            color #4D4
    &.absent
        text-decoration line-through #d99
        color rgba(0,0,0,.4)
        font-style italic

@media screen and (min-width 480px)
    #Component
        max-width 500px
        margin 1em auto
        background white
        box-shadow 0 .5em 1em 1px rgba(0,0,0,.2)
        
</style>