<template>
  <div class="Countdown fresh">
      <div class="slot">
        <div class="label">Jour{{cdate.days > 1 ? 's' : '' }}</div>
        <div class="numbers">
            <div class="number" v-for="(i, index) in String(cdate.days)" :key="`days-${index}-${i}`">
                {{i}}
            </div>
        </div>
      </div>
      <div class="slot">
        <div class="label">Heure{{cdate.hours > 1 ? 's' : '' }}</div>
        <div class="numbers">
            <div class="number" v-for="(i, index) in String(cdate.hours)" :key="`hours-${index}-${i}`">
                {{i}}
            </div>
        </div>
      </div>
      <div class="slot">
        <div class="label">Minute{{cdate.minutes > 1 ? 's' : '' }}</div>
        <div class="numbers">
            <div class="number" v-for="(i, index) in String(cdate.minutes)" :key="`minutes-${index}-${i}`">
                {{i}}
            </div>
        </div>
      </div>
      <div class="slot">
        <div class="label">Seconde{{cdate.seconds > 1 ? 's' : '' }}</div>
        <div class="numbers">
            <div class="number" v-for="(i, index) in String(cdate.seconds)" :key="`seconds-${index}-${i}`">
                {{i}}
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        'date': {
            default: new Date()
        }
    },
    data() {
        return {
            cdate: {days: 0, hours: 0, minutes: 0, seconds: 0},
            interval: 0
        }
    },
    computed: {
    },
    methods: {
        update() {
            if(this.date < Date.now()) {
                clearInterval(this.interval)
                this.cdate = {
                    days: String('000'),
                    hours: String('00'),
                    minutes: String('00'),
                    seconds: String('00')
                }
                return
            }
            let delta = Math.abs(this.date - Date.now()) / 1000;
            const days = Math.floor(delta / 86400);
            delta -= days * 86400;
            const hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;
            const minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;
            const seconds = Math.floor(delta % 60);

            this.cdate = {
                days: String('000' + days).substr(-3),
                hours: String('00' + hours).substr(-2),
                minutes: String('00' + minutes).substr(-2),
                seconds: String('00' + seconds).substr(-2)
            }
        }
    },
    mounted() {
        this.interval = setInterval(() => {
            this.update()
        }, 1000)
        this.update()
        setTimeout(() => {
            document.querySelector('.Countdown').classList.remove('fresh')
        }, 999)
    }
}
</script>

<style scoped lang="stylus">
.Countdown
    font-size 10pt
    zoom .7
    display inline-flex
    margin auto
    .slot
        display flex
        flex-direction column-reverse
        align-items center
        .label
            text-transform uppercase
            font-size .7em
            padding .5em
            opacity .5
            font-family sans-serif
        .numbers
            display flex
            margin 0 .5em
            transform-origin center center
            overflow visible
            perspective 400px
            transform-style preserve-3d
            .number
                font-size 2em
                font-family clock, monospace
                padding .5em
                color rgba(255,255,255,.85)
                background linear-gradient(to bottom, #333, #111 50%, #333 50%, #111)
                border 1px solid rgba(0,0,0,.25)
                border-bottom 3px solid rgba(255,255,255,.2)
                position relative
                border-radius .35em
                box-shadow 0 .1em .25em 1px rgba(0,0,0,.2)
.Countdown.fresh .number
    animation flip .15s ease-out 4!important
    &:nth-child(2)
        animation-delay .2s!important
.Countdown:not(.fresh) .number
    animation flip .33s ease-out!important
@keyframes flip
    from
        transform rotateX(180deg)
        color transparent
    50%
        color transparent
    to
        transform rotateX(360deg)
@media screen and (min-width: 768px)
    .Countdown
        font-size 16pt
        zoom 1
</style>