<template>
  <div class="wrapper" :class="{onScreen}" ref="comp">
      <slot />
  </div>
</template>

<script>
export default {
    props: {
        transition: {
            default: 'fade'
        },
        parent: {
            default: ''
        },
        duration: {
            default: 400
        },
        animateLeave: {
            default: false
        }
    },
    data: () => ({
        onScreen: false,
        animationRunning: false,
        lastY: 0,
        nextY: 0
    }),
    computed: {
        getParent() {
            return this.parent || this.$refs.comp.parentNode
        },
        scrollDirection() {
            return this.lastY < this.nextY ? 'DOWN' : 'UP' || 'DOWN'
        }
    },
    methods: {
        show() {
            this.$refs.comp.style.animation = `${this.transition}-in ${this.duration}ms ease-out forwards`
            this.onScreen = true
        },
        hide() {
            this.$refs.comp.style.animation = `${this.transition}-out ${this.duration}ms ease-out forwards`
            setTimeout(() => {
                this.onScreen = false
            }, this.duration)
        },
        onScroll(e) {
            // start scroll direction calculation
            this.lastY = this.nextY
            this.nextY = e.target.scrollTop
            // end scroll direction calculation

            // init variables
            let top = 0,
                left = 0,
                width = 0,
                height = 0,
                element = this.$refs.comp,
                bound = element.getBoundingClientRect()
            // end init variables

            // reverse-recursing nodes to get full offset
            height = bound.height
            width = bound.width
            do {
                bound = element.getBoundingClientRect()
                top += bound.top
                left += bound.left
                element = element.offsetParent
                if (element !== null) {
                    bound = element.getBoundingClientRect()
                    top -= bound.top - window.scrollY
                    left -= bound.left - window.scrollX
                }
            } while (element)
            // end reverse-recursing nodes
            
            // building coords object
            const coordsOnScreen = {
                top: top,
                left: left,
                width: width,
                height: height,
                bottom: top + height
            }

            // calculating additional offset for hysterisis
            const offset = 100 //   Math.min(coordsOnScreen.height, 100)

            // show/hide logic
            if (!this.onScreen) {
                if(this.scrollDirection == 'DOWN') {
                    if(coordsOnScreen.top < (window.innerHeight - offset)) {
                        this.show()
                    }
                }
                if (this.scrollDirection == 'UP') {
                    if(coordsOnScreen.bottom < (window.innerHeight - offset)) {
                        this.show()
                    }
                }
            } else {
                // do nothing if an animation is already running not to flicker
                if(this.animationRunning || !this.animateLeave) {
                    return
                }
                if(this.scrollDirection == 'DOWN') {
                    if (coordsOnScreen.bottom < offset) {
                        this.hide()
                    }

                }
                if (this.scrollDirection == 'UP') {
                    if (coordsOnScreen.top > window.innerHeight - offset) {
                        this.hide()
                    }
                }
            }
        }
    },
    mounted() {
        this.getParent.addEventListener('scroll', this.onScroll, true)
        this.onScroll({target: {scrollTop: 1}})
        this.$refs.comp.addEventListener('animationstart', () => {
            this.animationRunning = true
        }, true)
        this.$refs.comp.addEventListener('animationend', () => {
            this.$emit(this.onScreen && this.animationRunning ? 'shown' : 'hidden')
            this.animationRunning = false
        }, true)
    },
    beforeUnmount() {
        this.getParent.removeEventListener('scroll', this.onScroll, true)
    }
}
</script>
<style lang="stylus" scoped>
.wrapper:not(.onScreen)
    visibility hidden
.wrapper.onScreen
    visibility visible
</style>

<style lang="stylus">
@keyframes fade-in
    from
        opacity 0
    to
        opacity 1

@keyframes fade-out
    from
        opacity 1
    to
        opacity 0


@keyframes pop-in
    from
        opacity 0
        transform scale(0)
    80%
        transform scale(1.1)
    to
        opacity 1
        transform scale(1)

@keyframes pop-out
    from
        opacity 1
        transform scale(1)
    20%
        transform scale(1.1)
    to
        opacity 0
        transform scale(0)



@keyframes slideUp-in
    from
        opacity 0
        transform translateY(5rem)
    80%
        transform translateY(-1rem)
    to
        opacity 1
        transform translateY(0)
@keyframes slideUp-out
    from
        opacity 1
        transform translateY(0)
    to
        opacity 0
        transform translateY(-5rem)


@keyframes slideDown-in
    from
        opacity 0
        transform translateY(-5rem)
    80%
        transform translateY(1rem)
    to
        opacity 1
        transform translateY(0)
@keyframes slideDown-out
    from
        opacity 1
        transform translateY(0)
    to
        opacity 0
        transform translateY(5rem)
</style>