import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Reponse from '../views/Reponse.vue'
import Invites from '../views/Invites.vue'
import Photos from '../views/Photos.vue'
import PhotoUpload from '../views/PhotoUpload.vue'

const routes = [
  {
    path: '/invites',
    name: 'Invites',
    component: Invites
  },
  {
    path: '/photos/:album?',
    name: 'Photos',
    component: Photos
  },
  {
    path: '/photos_upload',
    name: 'PhotoUpload',
    component: PhotoUpload
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reponse',
    name: 'Reponse',
    component: Reponse
  },
  {
    path: '',
    redirect: { name: 'Home' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
