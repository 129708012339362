<template>
    <div class="mainMenu">
        <router-link exact :to="{name: 'Home'}">
            <img src="/img/ic_infos.svg" alt="" >
            <div>Infos</div>
        </router-link>
        <router-link :to="{name: 'Invites'}">
            <img src="/img/ic_list.svg" alt="" >
            <div>Liste des invités</div>
        </router-link>
        <router-link :to="{name: 'Reponse'}" v-if="!dateIsGone">
            <img src="/img/ic_enveloppe_open.svg" alt="" >
            <div>Répondre</div>
        </router-link>
        <router-link :to="{name: 'Photos'}" v-if="dateIsGone">
            <img src="/img/ic_photo.svg" alt="" >
            <div>Photos</div>
        </router-link>
    </div>
</template>

<script setup>
    import {computed } from 'vue'

    const dateIsGone = computed(() => {
        return Date.now() > new Date(2022,5,25,15,0,0)
    })
</script>

<style lang="stylus" scoped>
.mainMenu
    height 45pt
    display flex
    align-items center
    justify-content center
    font-family sans-serif
    font-size 1rem
    &>:first-child
        margin-left -1em
    a
        display flex
        align-items center
        color inherit
        text-decoration none
        position relative
        padding .5rem
        border-radius .25em
        white-space nowrap
        color #222
        img
            height 1.25rem
            width 1.25rem
            &+*:first-of-type
                margin-left .25em
        &::before
            content ''
            position absolute
            left 50%
            right 50%
            border-radius 1em
            height 2pt
            background salmon
            background #ED7A9D
            bottom 0
            display block
            opacity 0
            transition .2s
        &.router-link-exact-active
            &::before
                opacity 1
                left calc(50% - 1.5rem)
                right calc(50% - 1.5rem)
</style>