<template>
<div data-card>
    Ajoutez directement vos photos sur <a href="https://photos.app.goo.gl/5oUKdqYuHFWGbXHf9">notre album Google Photos</a>
</div>
<form method="post" enctype="multipart/form-data" action="/api/photo" target="result" style="min-width: 400px; margin: auto; display: flex; flex-direction: column;" @submit.stop.prevent="onSubmit">
    <div data-card v-if="!state.uploading">
    <p style="font-size: .85rem; display: block; width: 100%; opacity: .7">
        Autrement, pour éviter de polluer le site avec des photos venant de personnes non autorisées <span style="text-decoration: line-through">ou d'espions russes</span>, l'envoi de photos directement sur cette page nécessite la saisie d'un code fourni par les mariés sur demande.
        <br/>
        Saisissez simplement votre code, choisissez vos photos, et cliquez sur "<span style="color: salmon">Envoyer!</span>" ! (Mais Google Photos, c'est plus simple ;) )
    </p>
    </div>
    <div data-card  v-if="!state.uploading">
        <label>Code de l'album</label>
        <input name="code" placeholder="Code fourni par les mariés pour éviter le spam" required />
        <br/>
        <label>Vos photos</label>
        <input type="file" multiple accept="image/*" name="photo" required />
        <br />
        <button>
            Envoyer !
        </button>
        <br/>
        <small style="font-size: 10px; line-height: 14px; margin-top: 1rem; display: block; color: salmon">
            Plus il y a de photos, plus ça peut prendre de temps... n'hésitez pas a envoyer vos photos par petits lots plutôt que tout en une fois :)
        </small>
    </div>
</form>
<div v-if="state.uploading" data-card>
    Envoi en cours, merci de patienter...
    <progress :max="state.total" :value="state.progress" />
    <small>
        Vous pourrez encore ajouter des photos une fois l'envoi terminé !
    </small>
</div>
</template>

<script setup>
import {reactive} from 'vue';
import axios from 'axios'

const state = reactive({
    uploading: false,
    progres: 0,
    total: 0
})

    const onSubmit = (e) => {
        const fd = new FormData(e.target)
        state.uploading = false
        
        const config = {
            onUploadProgress: progressEvent => {
                state.progress = progressEvent.loaded
                state.total = progressEvent.total
            }
        }
        axios.post('/api/photo', fd, config).then((response) => {
            alert(response.data)
            setTimeout(() => {
                state.uploading = false
            }, 250)
            e.target.reset()
        })
    }
</script>

<style lang="stylus" scoped>
    input
        padding .5rem 1rem
        border 1px solid rgba(0,0,0,.15)
        border-radius .25rem
        width 100%
        box-sizing border-box
        margin-bottom 1rem
    button
        background salmon
        color white
        border-radius .25rem
        border none
        padding .6rem 1.5rem
        margin auto
        font-size 1.2rem
        box-shadow 0 .25rem .75rem rgba(0,0,0,.25)
</style>